export default {
  formatTotal(total) {
    return new Intl.NumberFormat("en-MY", {
      style: "currency",
      currency: "MYR",
    }).format(total || 0);
  },

  formatDate(value) {
    if (!value) return value;
    return new Intl.DateTimeFormat("en-MY", { dateStyle: "short" }).format(
      new Date(value)
    );
  },

  formatDateTime(value) {
    if (!value) return value;
    return new Intl.DateTimeFormat("en-MY", {
      hourCycle: "h12",
      timeStyle: "short",
      dateStyle: "short",
    })
      .format(new Date(value))
      .replace(/,/g, "");
  },

  getDays(startDate, endDate) {
    const daysArray = [];
    const currentDate = new Date(startDate);
    const endDateTime = new Date(endDate).getTime();

    while (currentDate <= endDateTime) {
      daysArray.push(`${currentDate.getMonth() + 1}/${currentDate.getDate()}`);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return daysArray;
  },

  getMonths(startDate, endDate) {
    const monthsArray = [];
    const startMonth = new Date(startDate).getMonth();
    const endMonth = new Date(endDate).getMonth();

    for (let i = startMonth; i <= endMonth; i++) {
      const monthName = new Date(0, i).toLocaleString("en-us", {
        month: "long",
      });
      monthsArray.push(monthName);
    }

    return monthsArray;
  },

  //Returns the integer value of the First and Last month from an array of months
  //Ex: ["April", "May",]
  //Output: 4, 5
  getFirstAndLastMonths(monthsArray) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const firstMonth = monthNames.indexOf(monthsArray[0]);
    const lastMonth =
      monthNames.indexOf(monthsArray[monthsArray.length - 1]) + 1;

    return { firstMonth, lastMonth };
  },

  getFirstAndLastDays(daysArray) {
    const firstDay = parseInt(daysArray[0].split("/")[1]) - 1;
    const lastDay = parseInt(daysArray[daysArray.length - 1].split("/")[1]);

    return { firstDay, lastDay };
  },
};
